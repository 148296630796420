import { ApiTableConfig, tableDataTypeEnum } from '../../../Common/Components/ApiTable/api-table-config.interface';
import { Vault } from '../../Service';

export const secretsTableConfig: ApiTableConfig<Vault> = [
  {
    title: 'All',
    columns: [
      { title: 'Name', property: 'name', width: 190, dataType: tableDataTypeEnum.STRING },
      { title: 'URL', property: 'url', width: 290, dataType: tableDataTypeEnum.STRING },
      { title: 'username', property: 'username', width: 190, dataType: tableDataTypeEnum.STRING },
      {
        title: 'allowed',
        property: 'allowed',
        width: 190,
        dataType: tableDataTypeEnum.STRING,
        render: (row) => row.allowed.join(', '),
      },
      {
        title: 'issued',
        property: 'issued',
        width: 190,
        dataType: tableDataTypeEnum.STRING,
        render: (row) => row.issued.join(', '),
      },
    ],
  },
];
