import { TextField } from '@material-ui/core';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeReason,
  AutocompleteProps as MuiAutocompleteProps,
  Value,
} from '@material-ui/lab';
import { FieldHookConfig, useField } from 'formik';
import * as React from 'react';

type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = {
  label?: string;
  onUpdate?: (fieldName: string, reason: AutocompleteChangeReason, value?: T) => void;
} & FieldHookConfig<Value<T, Multiple, DisableClearable, FreeSolo>> &
  Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> &
  Partial<Pick<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>>;

export function FormikAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const { label, onUpdate, onBlur: propsOnBlur, renderInput: propsRenderInput, options, ...rest } = props;
  const [field, meta, helpers] = useField(rest);
  const showError = meta.touched && !!meta.error;

  return (
    <>
      <MuiAutocomplete
        {...rest}
        {...field}
        options={options}
        onChange={(event, value, reason, details) => {
          onUpdate && onUpdate(props.name, reason, details?.option);
          helpers.setValue(value as any);
          // field.onChange && field.onChange(event);
          // propsOnChange && propsOnChange(event, value as any, reason, details);
        }}
        onBlur={(event) => {
          field.onBlur && field.onBlur(event);
          propsOnBlur && propsOnBlur(event);
        }}
        renderInput={(params) =>
          propsRenderInput ? (
            propsRenderInput(params)
          ) : (
            <TextField
              name={field.name}
              label={label}
              {...params}
              error={showError}
              helperText={showError ? meta.error : ''}
            />
          )
        }
      />
    </>
  );
}
export default FormikAutocomplete;
