/**
 * App Language Provider
 * Add more locales here
 */
import enLang from './locales/en_US';

export enum Language {
  EN = 'en',
}

type ILocale = {
  messages: any;
  locale: string;
};
type ILocaleMap = { [key in Language]: ILocale };

// Type '{ [x: string]: string; }' is not assignable to type 'TestMap'.
// Property 'a' is missing in type '{ [x: string]: string; }'.'
export const AppLocale: ILocaleMap = {
  [Language.EN]: enLang,
};

// export default AppLocale;
