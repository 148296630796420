import { Button, Grid, Typography } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { useHistory } from 'react-router';
interface NoAccessComponentProps {
  text?: string;
  goBackAction?: () => void;
  goBackActionButtonText?: string;
}
export const NoAccessComponent = (props: NoAccessComponentProps) => {
  const { text, goBackAction, goBackActionButtonText } = props;
  const { keycloak } = useKeycloak();
  const history = useHistory();
  /**@TODO make improvements*/
  return (
    <Grid item xs={12} container direction='column' alignItems='center'>
      <Grid item xs={12}>
        <Typography variant='h6'>{text ? text : 'You do not have access to view this page'}</Typography>
      </Grid>
      <Grid item container spacing={5} justify='center'>
        <Grid item>
          <Button
            color='primary'
            variant='contained'
            onClick={() => (goBackAction ? goBackAction() : history.goBack())}
          >
            {goBackActionButtonText || 'Go Back'}
          </Button>
        </Grid>
        <Grid item>
          <Button color='primary' size='small' onClick={() => keycloak.logout()}>
            Sign Out
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
