import { Common } from '@laco/backend-interfaces';
import { useKeycloak } from '@react-keycloak/web';
import { isArray } from 'lodash';
import { PrivateRouteProps } from './PrivateRoute';
export interface ModulePrivateRoute extends PrivateRouteProps {
  title?: string;
  path: string;
}
export const useUserAccessControl = () => {
  const { keycloak } = useKeycloak();

  const userHasAccess = (
    accessModule?: Common.Modules | Common.Modules[] | 'vault-service',
    accessRoles?: Common.Roles[],
    allRolesRequired = true,
  ) => {
    if (!accessModule && !accessRoles) return true;
    const keycloakResource = keycloak.resourceAccess;
    console.log({ keycloakResource });
    const availableRoles =
      keycloakResource &&
      accessModule &&
      (isArray(accessModule)
        ? accessModule
            .map((mod) => keycloakResource[mod]?.roles)
            .flat()
            .filter((f) => f)
        : keycloakResource[accessModule]?.roles);
    return availableRoles
      ? accessRoles
        ? allRolesRequired
          ? accessRoles?.every((role) => availableRoles.includes(role))
          : accessRoles?.some((role) => availableRoles.includes(role))
        : true
      : false;
  };

  return { userHasAccess };
};
