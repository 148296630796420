import { FormControlLabel, FormGroup } from '@material-ui/core';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import invariant from 'tiny-warning';

type SwitchProps = { label?: string; onUpdate?: (fieldName: string, value: boolean) => void } & MuiSwitchProps &
  FieldHookConfig<boolean>;

const FormikSwitch = (props: SwitchProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const { label, onUpdate, onBlur: propsOnBlur, ...rest } = props;
  const [field] = useField(rest);

  if (process.env.NODE_ENV !== 'production') {
    invariant(
      props.type === 'checkbox',
      `property type=checkbox is missing from field ${field.name}, this can caused unexpected behavior`,
    );
  }

  const onBlur = (e: React.FocusEvent<any>) => {
    field.onBlur && field.onBlur(e);
    propsOnBlur && propsOnBlur(e);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    field.onChange && field.onChange(e);
    onUpdate && onUpdate(props.name, checked);
  };
  return (
    <FormGroup row>
      <FormControlLabel
        control={<MuiSwitch {...field} {...rest} onBlur={onBlur} onChange={onChange} />}
        label={label}
      />
    </FormGroup>
  );
};

export default FormikSwitch;
