import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
export interface LoadingErrorIndicatorProps {
  state?: 'loading' | 'error';
  errorDetails?: string;
  handleRetryLoad?: () => void;
}
export const LoadingErrorIndicator = (props: LoadingErrorIndicatorProps) => {
  const { state, errorDetails, handleRetryLoad } = props;
  return (
    <Grid container direction='column' spacing={5} alignItems='center'>
      {state === 'loading' ? (
        <Grid item>
          <CircularProgress size={35} />
        </Grid>
      ) : (
        <>
          {errorDetails && (
            <Grid item>
              <Typography align='center'>{errorDetails}</Typography>
            </Grid>
          )}
          {handleRetryLoad && (
            <Grid item>
              <Button color='primary' onClick={handleRetryLoad}>
                Retry
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
