import { Common } from '@laco/backend-interfaces';
//MUI
/* Core */
import {
  Checkbox,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
/* Icons */
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
//Other
import { isArray } from 'lodash';
import React from 'react';
import { FilterDialog } from '../Dialogs/FilterDialog';
//Custom
import { ApiTableConfig, tableDataTypeEnum } from './api-table-config.interface';

export interface ApiTableProps<T> {
  config: ApiTableConfig<T>;
  tableData: T[];
  loading?: boolean;
  showChildrenWhenLoading?: boolean;
  totalRecords?: number;
  initSearch?: InitSearchType;
  initSort?: InitSortType;
  dense?: boolean;
  rowsPerPageOptions?: Array<number>;
  tabIndex?: number;
  maxHeight?: string;
  tableStickyHeader?: boolean;
  hideMainSearch?: boolean;
  selectable?: boolean;
  isNotSelectable?: (row: T) => boolean;
  nonSelectableCount?: number;
  handleTableUpdate?: (data: Omit<TableUpdateParams<T>, 'data'>) => void;
  handleRowOfTableClicked?: (id: string, rowIndex: number, data: TableUpdateParams<T>) => void;
  isSelected?: (row: T) => boolean;
  selectedIndexes?: number[];
  onSelectionChange?: (rowIndex: number | 'all', selected: boolean, data: T[]) => void;
  customComponentRenders?: { [index: string]: (row: T, rowIndex: number) => React.ReactNode | undefined };
}
interface InitSearchType {
  [index: number]: MinMaxValues | string | string[] | undefined;
  key: string;
}
interface InitSortType {
  [index: number]: SortDirection;
  key: string;
}
export interface MinMaxValues {
  [index: string]: number | string | string[] | undefined;
  minNumber?: number;
  maxNumber?: number;
  minDate?: string;
  maxDate?: string;
}
export type SortDirection = 'desc' | 'asc' | undefined;
export interface TableUpdateParams<T> {
  tabIndex: number;
  search?: Common.SearchElement[];
  sort?: string;
  pageNumb: number;
  rowsPerPage: number;
  data: T[];
}

const useStyles = makeStyles(() => ({
  tableNavigationButtons: {
    marginRight: '10px',
  },
  toolBarRoot: {
    display: 'felx',
  },
  toolBarSearch: {
    flex: 1,
    direction: 'rtl',
  },
  hidden: {
    '&:not(:hover)': {
      opacity: 0,
    },
    opacity: 0.5,
  },
}));
interface StringIndexSignature {
  [index: string]: any;
}
export const ApiTable = <TableDataType extends StringIndexSignature>(
  props: React.PropsWithChildren<ApiTableProps<TableDataType>>,
) => {
  const {
    config,
    tableData,
    loading,
    showChildrenWhenLoading,
    totalRecords,
    rowsPerPageOptions,
    isNotSelectable,
    nonSelectableCount,
    handleTableUpdate,
    handleRowOfTableClicked,
    isSelected,
    children,
    tabIndex,
    dense,
    maxHeight,
    tableStickyHeader,
    hideMainSearch,
    initSearch,
    initSort,
    customComponentRenders,
    selectedIndexes,
    onSelectionChange,
    selectable,
  } = props;
  const classes = useStyles();

  const [activeIndex, setActiveIndex] = React.useState<number>(tabIndex || 0);
  const [sortDirection, setSortDirection] = React.useState<SortDirection[]>(
    config[activeIndex].columns.map(() => undefined),
  );
  const [pageNumb, setPageNumb] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions ? rowsPerPageOptions[0] : 10);

  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [filterIndex, setFilterIndex] = React.useState(0);

  const [firstRun, setFirstRun] = React.useState(true);

  // search TextField for all columns
  const [search, setSearch] = React.useState('');
  // column specific search Text Field

  const [searchFields, setSearchFields] = React.useState<(MinMaxValues | string | string[])[]>(
    config[activeIndex].columns.map(() => ''),
  );

  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

  // function to build where condition (send to API as where)
  const buildSearch = () => {
    const result = [];

    if (search.trim()) {
      result.push({
        term: search.trim(),
        fields: config[activeIndex].columns
          .filter((f) => f.dataType === tableDataTypeEnum.STRING)
          .map((col) => col.property),
      });
    }

    searchFields.forEach((searchValue, index) => {
      const property = [config[activeIndex].columns[index].property];
      if (typeof searchValue === 'string') {
        searchValue && result.push({ term: searchValue, fields: property });
      } else if (isArray(searchValue)) {
        searchValue.length && result.push({ enums: searchValue, fields: property });
      } else {
        searchValue['fields'] = property;
        result.push({
          ...searchValue,
          minNumber: searchValue.minNumber ? `${searchValue.minNumber}` : undefined,
          maxNumber: searchValue.maxNumber ? `${searchValue.maxNumber}` : undefined,
        });
      }
    });
    return result;
  };

  const getTableUpdateParams = (): TableUpdateParams<TableDataType> => {
    const sort = sortDirection
      .map((direction, column) =>
        direction ? `${direction === 'asc' ? '' : '-'}${config[activeIndex].columns[column].property}` : '',
      )
      .join(' ')
      .trim();
    return {
      tabIndex: activeIndex,
      search: buildSearch(),
      sort: sort || undefined,
      pageNumb,
      rowsPerPage,
      data: tableData,
    };
  };

  const handleTabSwitch = (index: number) => {
    if (index !== activeIndex) {
      setSortDirection(config[index].columns.map(() => undefined));
    }
    setSearchFields(config[index].columns.map(() => ''));
    setActiveIndex(index);
  };

  const handleSortArrowClick = (column: number, shiftKey: boolean) => {
    const sort = sortDirection.map((dir, index) => {
      if (column === index) {
        if (dir === 'asc') return 'desc';
        if (dir === 'desc') return undefined;
        return 'asc';
      }
      return shiftKey ? dir : undefined;
    });
    setSortDirection(sort);
  };

  const handleChangeTablePage = (event: unknown, newPage: number) => {
    setPageNumb(newPage);
  };

  const handleChangeTableRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setPageNumb(0);
  };

  const handleSelection = (index: number | 'all', checked: boolean) => {
    let newSelectedRows: number[] = [];
    if (index === 'all') {
      newSelectedRows = checked
        ? (
            tableData
              .map((data, i) => {
                if (isNotSelectable === undefined || !isNotSelectable(data)) {
                  return i;
                }
                return undefined;
              })
              .filter((f) => f !== undefined) as number[]
          ).sort((a, b) => a - b)
        : [];
      setSelectedRows(newSelectedRows);
    } else {
      newSelectedRows = checked
        ? isNotSelectable
          ? !isNotSelectable(tableData[index])
            ? [...selectedRows, index].sort((a, b) => a - b)
            : [...selectedRows]
          : [...selectedRows, index].sort((a, b) => a - b)
        : selectedRows.filter((i) => i !== index);
      setSelectedRows(newSelectedRows);
    }
    onSelectionChange &&
      newSelectedRows.toString() !== selectedRows.toString() &&
      onSelectionChange(index, checked, tableData);
  };

  React.useEffect(() => {
    if (initSearch) {
      const mainSearch = initSearch[-1];
      (typeof mainSearch === 'string' || mainSearch === undefined) && setSearch(mainSearch || '');
      setSearchFields(searchFields.map((_, index) => initSearch[index] || ''));
    } else {
      setSearchFields(config[activeIndex].columns.map(() => ''));
    }
  }, [initSearch?.key]);

  React.useEffect(() => {
    initSort
      ? setSortDirection(sortDirection.map((_, index) => initSort[index]))
      : setSortDirection(config[activeIndex].columns.map(() => undefined));
  }, [initSort?.key]);

  React.useEffect(() => {
    setSelectedRows([]);
    !firstRun && onSelectionChange && onSelectionChange('all', false, tableData);
  }, [pageNumb]);

  React.useEffect(() => {
    if (tabIndex !== undefined && !firstRun) handleTabSwitch(tabIndex);
  }, [tabIndex]);

  React.useEffect(() => {
    !firstRun && handleTableUpdate && handleTableUpdate(getTableUpdateParams());
    setFirstRun(false);
  }, [activeIndex, pageNumb, rowsPerPage, search, sortDirection, searchFields]);

  React.useEffect(() => {
    selectedIndexes && setSelectedRows(selectedIndexes);
  }, [selectedIndexes]);

  const selectedRowsText = selectedRows.length ? (
    <div style={{ marginRight: 5, marginLeft: 5 }}>
      <Typography>{`${selectedRows.length} item${selectedRows.length > 1 ? 's' : ''} selected`}</Typography>
    </div>
  ) : null;

  const childrenWithDiv = <div style={{ marginLeft: 5, marginRight: 5 }}>{children}</div>;

  const linearProgress = (
    <div style={{ flex: 1, marginLeft: 5, marginRight: 5 }}>
      <LinearProgress />
    </div>
  );

  return (
    <Paper>
      <Toolbar className={classes.toolBarRoot}>
        <Grid container alignItems='flex-end' spacing={5}>
          {config.length > 1 && tabIndex === undefined ? (
            config.map((value, index) => {
              return (
                <Chip
                  key={index}
                  disabled={index === activeIndex}
                  color='primary'
                  label={value.title}
                  onClick={() => handleTabSwitch(index)}
                  className={classes.tableNavigationButtons}
                />
              );
            })
          ) : (
            <Grid item xs={!hideMainSearch ? 8 : 12}>
              {loading ? (
                showChildrenWhenLoading ? (
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {childrenWithDiv}
                    {selectedRowsText}
                    {linearProgress}
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {selectedRowsText}
                    {linearProgress}
                  </div>
                )
              ) : (
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  {childrenWithDiv}
                  {selectedRowsText}
                </div>
              )}
            </Grid>
          )}
          {!hideMainSearch && (
            <Grid item xs={4}>
              <TextField
                id='table-search'
                label='Search Table'
                fullWidth
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </Toolbar>
      <TableContainer style={{ height: maxHeight ? maxHeight : 'none' }}>
        <Table stickyHeader={tableStickyHeader} size={dense ? 'small' : undefined} aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {config[activeIndex].columns.map((column, columnIndex) => {
                const fieldValue: string | MinMaxValues | string[] = searchFields[columnIndex];
                return (
                  <TableCell align={column.align} key={columnIndex} style={{ minWidth: column.width }}>
                    <Grid container direction='column'>
                      <Grid item container direction='row' alignItems='center'>
                        {selectable && columnIndex === 0 && (
                          <Grid item>
                            <Checkbox
                              size='small'
                              checked={Boolean(
                                tableData.length &&
                                  selectedRows.length &&
                                  tableData.length - (nonSelectableCount || 0) === selectedRows.length,
                              )}
                              disabled={tableData.length <= (nonSelectableCount || 0)}
                              onChange={(_, checked) => handleSelection('all', checked)}
                            />
                          </Grid>
                        )}
                        {
                          <Grid item>
                            {column.titleTooltip ? (
                              <Tooltip title={column.titleTooltip}>
                                <div>{column.title}</div>
                              </Tooltip>
                            ) : (
                              column.title
                            )}
                          </Grid>
                        }
                        {!column.disableFilter && (
                          <Grid item>
                            <IconButton
                              size='small'
                              onClick={() => {
                                setOpenFilterDialog(true);
                                setFilterIndex(columnIndex);
                              }}
                              style={{ marginLeft: '5px' }}
                              className={classes.hidden}
                            >
                              <FilterListRoundedIcon />
                            </IconButton>
                          </Grid>
                        )}
                        {!column.disableSort && (
                          <Grid item>
                            <TableSortLabel
                              active={!!sortDirection[columnIndex]}
                              direction={sortDirection[columnIndex]}
                              onClick={(event) => handleSortArrowClick(columnIndex, event.shiftKey)}
                            />
                          </Grid>
                        )}
                      </Grid>
                      {typeof fieldValue === 'string' && fieldValue ? (
                        <Grid item>
                          <Chip
                            label={fieldValue}
                            color='primary'
                            onDelete={() => {
                              const newSearchFields = [...searchFields];
                              newSearchFields[columnIndex] = '';
                              setSearchFields(newSearchFields);
                            }}
                            onClick={() => {
                              setOpenFilterDialog(true);
                              setFilterIndex(columnIndex);
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid container spacing={5}>
                          {fieldValue && !isArray(fieldValue) && typeof fieldValue !== 'string'
                            ? Object.keys(fieldValue).map((key, index) => {
                                return key !== 'fields' ? (
                                  <Grid item key={index}>
                                    <Chip
                                      label={`${key.substring(0, 3)}: ${fieldValue[key]}`}
                                      color='primary'
                                      onDelete={() => {
                                        const newSearchFields = [...searchFields];
                                        delete (newSearchFields[columnIndex] as MinMaxValues)[key];
                                        setSearchFields(newSearchFields);
                                      }}
                                      onClick={() => {
                                        setOpenFilterDialog(true);
                                        setFilterIndex(columnIndex);
                                      }}
                                    />
                                  </Grid>
                                ) : null;
                              })
                            : isArray(fieldValue) &&
                              fieldValue.map((key, index) => (
                                <Grid item key={index}>
                                  <Chip
                                    label={key}
                                    color='primary'
                                    onDelete={() => {
                                      const newSearchFields = [...searchFields];
                                      (newSearchFields[columnIndex] as string[]).splice(index, 1);
                                      setSearchFields(newSearchFields);
                                    }}
                                    onClick={() => {
                                      setOpenFilterDialog(true);
                                      setFilterIndex(columnIndex);
                                    }}
                                  />
                                </Grid>
                              ))}
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((line, index) => {
              const columnProperties = config[activeIndex].columns.map((columnData) => columnData);
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                  selected={typeof isSelected === 'function' ? isSelected(line) : false}
                  onClick={() =>
                    handleRowOfTableClicked
                      ? handleRowOfTableClicked(line._id, index, getTableUpdateParams())
                      : selectable && handleSelection(index, !selectedRows.includes(index))
                  }
                >
                  {columnProperties.map((columnProperty, indexOfProperty) => (
                    <TableCell align={columnProperty.align || 'left'} key={`${index}${indexOfProperty}`}>
                      <Grid container direction='row' alignItems='center'>
                        {selectable && indexOfProperty === 0 && (
                          <Checkbox
                            size='small'
                            checked={selectedRows.includes(index)}
                            disabled={isNotSelectable && isNotSelectable(line)}
                            onChange={(event, checked) => {
                              event.stopPropagation();
                              handleSelection(index, checked);
                            }}
                          />
                        )}
                        {columnProperty.customRenderKey &&
                        customComponentRenders &&
                        customComponentRenders[columnProperty.customRenderKey]
                          ? customComponentRenders[columnProperty.customRenderKey](line, index)
                          : columnProperty.render
                          ? columnProperty.render(line)
                          : line[columnProperty.property]}
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {rowsPerPageOptions && rowsPerPageOptions.length ? (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={totalRecords || tableData.length}
          rowsPerPage={rowsPerPage}
          page={pageNumb}
          onChangePage={handleChangeTablePage}
          onChangeRowsPerPage={handleChangeTableRowsPerPage}
        />
      ) : null}
      <FilterDialog
        key={filterIndex}
        open={openFilterDialog}
        columnName={config[activeIndex].columns.length ? config[activeIndex].columns[filterIndex].title : ''}
        filterType={
          config[activeIndex].columns.length
            ? config[activeIndex].columns[filterIndex].dataType
            : tableDataTypeEnum.STRING
        }
        initFilterValue={searchFields[filterIndex] ? searchFields[filterIndex] : ''}
        filterAdded={(filterValue) => {
          const newSearchFields = [...searchFields];
          newSearchFields[filterIndex] = filterValue;
          setSearchFields(newSearchFields);
        }}
        closeDialog={() => setOpenFilterDialog(false)}
        enums={config[activeIndex].columns.length ? config[activeIndex].columns[filterIndex].enums : undefined}
      />
    </Paper>
  );
};
