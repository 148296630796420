import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import FormikAutocomplete from '../../Common/Components/Formik/FormikAutocomplete';
import FormikSwitch from '../../Common/Components/Formik/FormikSwitch';
import FormikTextField from '../../Common/Components/Formik/FormikTextField';
import { Vault } from '../Service';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5),
  },
}));

export type FormVault = Omit<Vault, '_id' | 'id' | 'metadata'>;
interface Props {
  secret: Vault | undefined;
  create: (secret: FormVault) => Promise<void>;
  update: (fieldName: string, value: string) => void;
  setActive: (fieldName: string, value: boolean) => void;
  setMultiple: (fieldName: string, reason: AutocompleteChangeReason, value?: string) => void;
  getUsers: () => Promise<string[]>;
}

export const EditSecretForm = (props: Props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [users, setUsers] = React.useState<string[]>([]);
  const secretEl = React.useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  const { secret, create, update, setActive, setMultiple } = props;
  const initialValues: FormVault = {
    name: secret?.name || '',
    username: secret?.username || '',
    secret: secret?.secret || '',
    url: secret?.url || '',
    allowed: secret?.allowed || [],
    issued: secret?.issued || [],
    active: secret === undefined ? true : secret.active,
    notes: secret?.notes || '',
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => event.preventDefault();
  const handleCopyPassword = () =>
    navigator.clipboard
      .writeText(secretEl.current?.value || '')
      .then(() => enqueueSnackbar('Password copied to clipboard', { variant: 'success', preventDuplicate: true }));
  const loadUsers = async () => {
    const usersLoaded = await props.getUsers();
    setUsers(usersLoaded);
  };
  React.useEffect(() => {
    loadUsers();
  }, [secret]);
  console.log({ users });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string().required('Secret name is required'),
        url: Yup.string().required('Url is required'),
        username: Yup.string().required('Username is required'),
        secret: Yup.string().required('Secret is required'),
        active: Yup.boolean().required(),
        allowed: Yup.array(Yup.string().required()),
        issued: Yup.array(Yup.string().required()),
      })}
      onSubmit={(values, { setSubmitting }) => {
        if (secret === undefined) {
          create(values).then(() => setSubmitting(false));
        }
      }}
    >
      <Form id='edit-secret-form-id' className={classes.root}>
        <FormikTextField
          fullWidth
          id='secret-name'
          name='name'
          label='Name'
          type='text'
          placeholder='name'
          onUpdate={update}
        />
        <FormikTextField fullWidth id='url' name='url' label='Url' type='text' placeholder='url' onUpdate={update} />
        <FormikTextField
          fullWidth
          id='secret-username'
          name='username'
          label='Username'
          type='text'
          placeholder='username'
          onUpdate={update}
        />
        <FormikTextField
          fullWidth
          id='secret'
          name='secret'
          label='Secret'
          type={showPassword ? 'text' : 'password'}
          placeholder='secret'
          onUpdate={update}
          inputRef={secretEl}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                {document.queryCommandSupported('copy') && (
                  <IconButton
                    aria-label='copy password to clipboard'
                    onClick={handleCopyPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <FileCopyIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        {secret ? (
          <>
            <FormikSwitch
              id='secret-active'
              name='active'
              label='Active'
              color='primary'
              type='checkbox'
              onUpdate={setActive}
            />
            {/* {
            <Autocomplete
              multiple
              freeSolo
              id='secret-allowed'
              options={options}
              // getOptionLabel={(option) => option.label}
              value={values.allowed}
              onChange={(e, value) => {
                console.log('changed', { value });
                setFieldValue('allowed', value);
              }}
              renderInput={(params) => <TextField {...params} name='allowed' label='User allowed' fullWidth />}
            />} */}
            <FormikAutocomplete
              multiple
              freeSolo
              label='Users allowed'
              name='allowed'
              id='secret-allowed'
              options={users}
              fullWidth
              onUpdate={setMultiple}
            />
            <FormikAutocomplete
              multiple
              freeSolo
              label='Users issued'
              name='issued'
              id='secret-issued'
              options={users}
              fullWidth
              onUpdate={setMultiple}
            />
            <FormikTextField
              fullWidth
              multiline
              rowsMax={5}
              rows={3}
              id='secret-notes'
              name='notes'
              label='Notes'
              type='text'
              placeholder='notes'
              onUpdate={update}
            />
          </>
        ) : null}
      </Form>
    </Formik>
  );
};
