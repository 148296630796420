import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useKeycloak } from '@react-keycloak/web';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { Loading } from './Common/Components/Loading';
import { ThemeProvider } from './Common/Layout/Theme';
import { MainAppRoutes } from './Common/Routes/MainApp.routes';
import { MainAppProvider } from './Common/Util/Providers';

const App = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <Loading />;
  }

  return (
    <ThemeProvider>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <MainAppProvider>
            <Router>
              <Switch>
                <Route path='/' component={MainAppRoutes} />
              </Switch>
            </Router>
          </MainAppProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
