let config: {
  nodeEnv?: string;
  // Api location
  apiUri?: string;
} = {};

if (process.env.NODE_ENV === 'production') {
  config = {
    nodeEnv: process.env.NODE_ENV,
    apiUri: '/api/v1',
  };
} else {
  config = {
    nodeEnv: process.env.NODE_ENV,
    apiUri: process.env.REACT_APP_API_URI,
  };
}

export { config };
