import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { usePrivateDashboardModules } from './routes';

export const DashboardRoutes = () => {
  const match = useRouteMatch();
  const dashboardModules = usePrivateDashboardModules();

  return (
    <Switch>
      <Redirect exact from={match.url} to={dashboardModules[0].path} />
      {dashboardModules.map((m, index) => (
        <PrivateRoute key={index} {...m} />
      ))}
    </Switch>
  );
};
