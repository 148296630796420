import { Common } from '@laco/backend-interfaces';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { NoAccessComponent } from './NoAccessComponent';
import { useUserAccessControl } from './private-routes.service';

export interface AccessControl {
  accessModule?: Common.Modules | Common.Modules[] | 'vault-service';
  accessRoles?: Common.Roles[];
  allRolesRequired?: boolean;
}
export interface PrivateRouteProps extends RouteProps, AccessControl {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  accessModule,
  accessRoles,
  allRolesRequired,
  ...rest
}: PrivateRouteProps) {
  const { keycloak } = useKeycloak();
  const { userHasAccess } = useUserAccessControl();

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? (
          userHasAccess(accessModule, accessRoles, allRolesRequired) ? (
            <Component {...props} />
          ) : (
            <NoAccessComponent />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.history.createHref(props.location) },
            }}
          />
        )
      }
    />
  );
}
