import { Common } from '@laco/backend-interfaces';
import { useKeycloak } from '@react-keycloak/web';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { config } from '../Config';
//Custom
import { MainAppContextValues } from './main-app-interface';

const MainAppContext = React.createContext({} as MainAppContextValues);
export const useMainAppProvider = () => React.useContext(MainAppContext);
export const MainAppProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { keycloak, initialized } = useKeycloak();

  const { enqueueSnackbar } = useSnackbar();
  const kcToken = keycloak?.token ?? '';

  const [dashboardDrawerOpen, setDashboardDrawerOpen] = React.useState(true);
  const [error, setError] = React.useState<AxiosError<Common.BadRequestResponse | Common.NotFoundResponse>>();

  const openDashboardDrawer = () => setDashboardDrawerOpen(true);
  const closeDashboardDrawer = () => setDashboardDrawerOpen(false);
  const toggleDashboardDrawer = () => setDashboardDrawerOpen(!dashboardDrawerOpen);

  React.useEffect(() => {
    if (error?.response) {
      console.log({ error });
      const messages = error.response.data.message;
      typeof messages === 'string'
        ? enqueueSnackbar(messages, { variant: 'error', preventDuplicate: true })
        : messages.forEach((m) => enqueueSnackbar(m, { variant: 'error', preventDuplicate: true }));
    }
  }, [error]);

  const createSuccessMessage = (mes: string) => enqueueSnackbar(mes, { variant: 'success', preventDuplicate: true });

  const resolvePromise = (promise: Promise<any>, successMessage?: string) =>
    promise
      .then((res) => {
        successMessage && createSuccessMessage(successMessage);
        return res.data;
      })
      .catch((err) => {
        console.log({ err });
        setError(err);
        return undefined;
      });

  const createAxiosInstance = () =>
    axios.create({
      baseURL: config.apiUri,
      headers: {
        Authorization: initialized ? `Bearer ${kcToken}` : undefined,
      },
    });

  const axiosInstance = React.useRef<AxiosInstance>(createAxiosInstance());

  React.useEffect(() => {
    console.log('token changed', { resourceAccess: keycloak.resourceAccess });
    axiosInstance.current = createAxiosInstance();
  }, [config.apiUri, initialized, kcToken]);

  return (
    <MainAppContext.Provider
      value={{
        error,
        resolvePromise,
        dashboardDrawerOpen,
        openDashboardDrawer,
        closeDashboardDrawer,
        toggleDashboardDrawer,
        axios: axiosInstance,
      }}
    >
      {children}
    </MainAppContext.Provider>
  );
};
