import { Common } from '@laco/backend-interfaces';

export interface Vault {
  _id: string;
  id: string;
  metadata: Common.Metadata;
  name: string;
  username: string;

  secret: string;
  url: string;
  active: boolean;
  allowed: string[];
  issued: string[];
  notes: string;
}
export class VaultSearchResponse extends Common.PaginatedResponse<Vault> {
  data!: Vault[];
}
