import { createMuiTheme } from '@material-ui/core/styles';
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff2424', //#ff6163
      light: '#ff8c8c',
    },
    secondary: {
      main: '#ff9920',
    },
  },
  typography: {
    caption: {
      fontSize: 15,
      color: 'gray',
    },
  },
  spacing: 2,
});
