import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { ApiTable, ApiTableProps, TableUpdateParams } from '../../../Common/Components/ApiTable/ApiTable';
import { useSecretsService, Vault } from '../../Service';
import { EditSecretDialog } from '../EditSecretDialog';
import { secretsTableConfig as config } from './SecretsTableConfig';
export type SecretsTableProps = Omit<
  ApiTableProps<Vault>,
  'handleTableUpdate' | 'totalRecords' | 'tableData' | 'handleRowOfTableClicked' | 'loading'
> & {
  handleRowOfTableClicked?: (secret: Vault) => void;
};
export const SecretsTable = (props: React.PropsWithChildren<SecretsTableProps>) => {
  const { children, handleRowOfTableClicked, ...rest } = props;

  const { find, reload } = useSecretsService();
  const [secrets, setSecrets] = React.useState<Vault[]>([]);
  const [secretsLoading, setSecretsLoading] = React.useState(false);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [selectedSecret, setSelectedSecret] = React.useState<Vault | undefined>();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const componentIsMounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const getTableData = async (data: Omit<TableUpdateParams<Vault>, 'data'>) => {
    setSecretsLoading(true);
    find({
      where: config[data.tabIndex].condition,
      search: data.search,
      page: data.pageNumb + 1,
      limit: data.rowsPerPage,
      sort: data.sort,
    }).then((resp) => {
      setSecretsLoading(false);
      if (resp && componentIsMounted.current) {
        setSecrets(resp.data);
        setTotalRecords(resp.filtered || 0);
      }
    });
  };

  const handleReload = async () => {
    setSecretsLoading(true);
    setSelectedSecret(undefined);

    reload()
      .then((resp) => {
        setSecretsLoading(false);
        if (resp && componentIsMounted.current) {
          setSecrets(resp.data);
          setTotalRecords(resp.filtered || 0);
        }
      })
      .catch((e) => console.log('Reload error', e));
  };

  React.useEffect(() => {
    !rest.initSearch &&
      getTableData({
        tabIndex: 0,
        pageNumb: 0,
        rowsPerPage: rest.rowsPerPageOptions ? rest.rowsPerPageOptions[0] : 25,
      });
  }, []);

  return (
    <>
      <ApiTable
        {...rest}
        handleRowOfTableClicked={
          handleRowOfTableClicked
            ? (_, rowIndex) => handleRowOfTableClicked(secrets[rowIndex])
            : (_, rowIndex) => {
                setSelectedSecret(secrets[rowIndex]);
                setDialogOpen(true);
              }
        }
        loading={secretsLoading}
        showChildrenWhenLoading={true}
        tableData={secrets}
        totalRecords={totalRecords}
        handleTableUpdate={getTableData}
      >
        <>
          {children}
          <Button color='primary' variant='contained' onClick={() => setDialogOpen(true)}>
            <AddIcon />
          </Button>
        </>
      </ApiTable>
      {dialogOpen ? (
        <EditSecretDialog
          open={dialogOpen}
          secretId={selectedSecret?._id}
          handleClose={() => {
            setDialogOpen(false);
            handleReload();
          }}
        />
      ) : null}
    </>
  );
};
