import { TableCellProps } from '@material-ui/core';
export interface ApiTableColumn<T> {
  title: string;
  property: string;
  width: number;
  dataType: tableDataTypeEnum;
  render?: (row: T) => string | number | React.ReactNode;
  titleTooltip?: string;
  customRenderKey?: string;
  enums?: string[];
  disableFilter?: boolean;
  disableSort?: boolean;
  align?: TableCellProps['align'];
}

export enum tableDataTypeEnum {
  STRING,
  DATE,
  NUMBER,
  ENUM,
  COMPONENT,
}

interface ApiTableTab<T> {
  title: string;
  condition?: any;
  columns: ApiTableColumn<T>[];
}

export type ApiTableConfig<T> = ApiTableTab<T>[];
