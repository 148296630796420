//MUI
/* Core */
import { MuiThemeProvider, Theme } from '@material-ui/core';
import React from 'react';
//Other
import { IntlProvider } from 'react-intl';
//Custom
import { AppLocale, Language } from '../../Util/Lang';
// App locale
// import AppLocale from "../lang";
// themes
import { theme as defaultTheme } from './default';

// import primaryTheme from "./themes/primaryTheme";
// import darkTheme from "./themes/darkTheme";
// import secondaryTheme from "./themes/secondaryTheme";
// import warningTheme from "./themes/warningTheme";
// import dangerTheme from "./themes/dangerTheme";
// import infoTheme from "./themes/infoTheme";
// import successTheme from "./themes/successTheme";

interface ThemeProviderProps {
  locale?: Language;
  rtlLayout?: boolean;
  activeTheme?: { id: number };
}

export const ThemeProvider = (props: React.PropsWithChildren<ThemeProviderProps>) => {
  const { locale: localeProps, rtlLayout, activeTheme, children } = props;
  const locale = localeProps || Language.EN;
  const intlProps = {
    messages: AppLocale[locale],
    locale: locale.toString(),
  };

  // theme changes
  let theme: Theme;
  switch (activeTheme?.id || 0) {
    //   // case 1:
    //   //   theme = primaryTheme;
    //   //   break;
    //   // case 2:
    //   //   theme = secondaryTheme;
    //   //   break;
    //   // case 3:
    //   //   theme = warningTheme;
    //   //   break;
    //   // case 4:
    //   //   theme = infoTheme;
    //   //   break;
    //   // case 5:
    //   //   theme = dangerTheme;
    //   //   break;
    //   // case 6:
    //   //   theme = successTheme;
    //   //   break;
    default:
      theme = defaultTheme;
      break;
  }

  if (rtlLayout) {
    theme.direction = 'rtl';
  } else {
    theme.direction = 'ltr';
  }

  return (
    <MuiThemeProvider theme={theme}>
      <IntlProvider {...intlProps}>
        <>{children}</>
      </IntlProvider>
    </MuiThemeProvider>
  );
};

// map state to props
// const mapStateToProps = ({ settings }) => {
//   return settings;
// };

// export default connect(mapStateToProps)(RctThemeProvider);
