//MUI
/* Core */
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
/* Icons */
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
//Custom
import { AppBarProfile } from './AppBarProfile';

interface AppBarProps extends MuiAppBarProps {
  handleDrawerToggle: () => void;
}

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
export const AppBar = (props: AppBarProps) => {
  const classes = useStyles();
  const { handleDrawerToggle, ...rest } = props;
  return (
    <MuiAppBar position='fixed' {...rest}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant='h6' noWrap>
          LACO
        </Typography>
        <AppBarProfile />
      </Toolbar>
    </MuiAppBar>
  );
};
