//MUI
/* Core */
import { Drawer, makeStyles } from '@material-ui/core';
import React from 'react';
//Router
import { useHistory } from 'react-router-dom';
import { DashboardRoutes } from '../Routes/Dashboard.routes';
import Logo from '../Util/Assets/Laco-logo-slogan.png';
import { useMainAppProvider } from '../Util/Providers';
//Custom
import { AppBar } from './Components/AppBar';
import { SideBar } from './Components/SideBar';

const drawerWidth = 240;

const useStyles = (drawerOpen: boolean) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexGrow: 1,
    },
    drawer: {
      width: drawerOpen ? drawerWidth : 0,
      flexShrink: 0,
    },
    appBar: {
      flexGrow: 1,
      width: drawerOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      overflowY: 'hidden',
    },
    logo: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      padding: '10px',
    },
  }));

interface DashboardPageProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container?: any;
}

export const DashboardPage = (props: DashboardPageProps) => {
  const { container } = props;
  const { dashboardDrawerOpen, toggleDashboardDrawer } = useMainAppProvider();
  const classes = useStyles(dashboardDrawerOpen)();

  const history = useHistory();

  const clickTabHandler = (text: string) => {
    history.push('/dashboard/' + text.replace(/\s/g, '-').toLowerCase());
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img src={Logo} alt='Logo' className={classes.logo} />
      </div>
      <SideBar clickTabHandler={clickTabHandler} />
    </div>
  );

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <AppBar color='primary' handleDrawerToggle={toggleDashboardDrawer} className={classes.appBar} />
      <nav className={classes.drawer} aria-label='mailbox folders'>
        <Drawer
          container={container}
          variant='persistent'
          open={dashboardDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <DashboardRoutes />
      </main>
    </div>
  );
};
