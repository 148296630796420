import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { AutocompleteChangeReason } from '@material-ui/lab';
import React from 'react';
import { LoadingErrorIndicator } from '../../Common/Components/LoadingErrorIndicator';
import { UpdateVault, useSecretsService, Vault } from '../Service';
import { EditSecretForm, FormVault } from './EditSecretForm';
interface Props {
  open: boolean;
  secretId?: string;
  handleClose: () => void;
}

export const EditSecretDialog = (props: Props) => {
  const { open, secretId, handleClose } = props;
  const {
    getById,
    create,
    update,
    setActive,
    addAllowedUser,
    removeAllowedUser,
    addIssuedUser,
    removeIssuedUser,
    getUsers,
  } = useSecretsService();
  const [loadingState, setLoadingState] = React.useState<'loading' | 'error' | undefined>();
  const [secret, setSecret] = React.useState<Vault | undefined>();

  const handleCreate = async (newSecret: FormVault) => {
    const { name, username, secret: createSecret, url } = newSecret;
    create(name, username, createSecret, url)
      .then((response) => setSecret(response))
      .catch((e) => {
        setLoadingState('error');
        console.error('error creating secret', e);
      });
  };

  const handleUpdate = (fieldName: string, value: string) => {
    const updateDocument: UpdateVault = {};
    updateDocument[fieldName] = value;
    secret &&
      update(secret._id, secret.metadata.documentVersion, updateDocument)
        .then((res) => setSecret(res))
        .catch((e) => console.error('update', e));
  };

  const handleSwitch = (_fieldName: string, value: boolean) => {
    secret &&
      setActive(secret._id, secret.metadata.documentVersion, value)
        .then((res) => setSecret(res))
        .catch((e) => console.error('activate', e));
  };

  const handleMultiple = (fieldName: string, reason: AutocompleteChangeReason, value?: string) => {
    let promise: Promise<Vault> | undefined;
    if (secret) {
      if (reason === 'create-option' || reason === 'select-option') {
        if (value && fieldName === 'allowed')
          promise = addAllowedUser(secret._id, secret.metadata.documentVersion, value);
        if (value && fieldName === 'issued')
          promise = addIssuedUser(secret._id, secret.metadata.documentVersion, value);
      }
      if (reason === 'remove-option') {
        if (value && fieldName === 'allowed')
          promise = removeAllowedUser(secret._id, secret.metadata.documentVersion, value);
        if (value && fieldName === 'issued')
          promise = removeIssuedUser(secret._id, secret.metadata.documentVersion, value);
      }
      if (reason === 'clear') {
        //TODO do something !!!
      }
    }
    promise &&
      promise
        .then((res) => setSecret(res))
        .then(() => getUsers(true))
        .catch((e) => console.error(`multiple options ${fieldName} ${reason} ${value}`, e));
  };
  const loadSecret = () => {
    if (secretId) {
      setLoadingState('loading');
      getById(secretId)
        .then((response) => {
          setSecret(response);
          setLoadingState(undefined);
        })
        .catch((e) => {
          console.error('error loading secret', e);
          setLoadingState('error');
        });
    } else {
      setSecret(undefined);
    }
  };

  React.useEffect(() => {
    loadSecret();
    return () => setSecret(undefined);
  }, [secretId]);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth aria-labelledby='secret-edit'>
      <DialogTitle id='secret-edit-title'>{secret ? 'Edit' : 'Add'} Secret</DialogTitle>
      <DialogContent>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            {loadingState ? (
              <LoadingErrorIndicator
                state={loadingState}
                errorDetails='Error loading secret'
                handleRetryLoad={() => loadSecret()}
              />
            ) : (
              <EditSecretForm
                secret={secret}
                create={handleCreate}
                update={handleUpdate}
                setActive={handleSwitch}
                setMultiple={handleMultiple}
                getUsers={getUsers}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {secret ? null : (
          <Button variant='contained' color='primary' type='submit' form='edit-secret-form-id'>
            Create
          </Button>
        )}
        <Button variant='contained' color='secondary' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
