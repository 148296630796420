import React from 'react';
//Router
import { Redirect, Route, Switch } from 'react-router-dom';
import { DashboardPage } from '../Layout/DashboardPage';
import { LoginPage } from '../Layout/LoginPage';
//Custom
import { PrivateRoute } from './PrivateRoute';

export const MainAppRoutes = () => {
  return (
    <Switch>
      <Redirect exact from='/' to='/dashboard' />
      <Route exact path='/login' component={LoginPage} />
      {/* <PrivateRoute {...props} path="/profile" component={ProfilePage} /> */}
      <PrivateRoute path='/dashboard' component={DashboardPage} accessModule={'vault-service'} />
    </Switch>
  );
};
