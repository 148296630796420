import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';

interface SideBarProps {
  clickTabHandler: (text: string) => void;
}
export const SideBar = (props: SideBarProps) => {
  return (
    <>
      <Divider />
      <List>
        {['Secrets'].map((text) => {
          return (
            <ListItem button key={text} onClick={() => props.clickTabHandler(text)}>
              <ListItemText primary={text} />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
