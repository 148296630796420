import { Common } from '@laco/backend-interfaces';
import moment from 'moment';
import React from 'react';
import { useMainAppProvider } from '../../Common/Util/Providers';
import { UpdateVault } from './update.vault.interface';
export interface LocalStorageUsers {
  users?: string[];
  expires?: Date;
}
const USERS_LOCAL_STORAGE_KEY = 'traverse.pw.users';

export const useSecretsService = () => {
  const { axios, resolvePromise } = useMainAppProvider();
  const [query, setQuery] = React.useState<Common.SearchAction | undefined>();

  const find = (findQuery?: Common.SearchAction) => {
    setQuery(findQuery);
    return resolvePromise(axios.current.post('/vault/search', findQuery));
  };
  const reload = () => resolvePromise(axios.current.post('/vault/search', query));

  const getById = (id: string) => resolvePromise(axios.current.get(`/vault/${id}`));

  const getUsers = async (force = false) => {
    let localStorageUsers: LocalStorageUsers = {};
    try {
      localStorageUsers = JSON.parse(localStorage.getItem(USERS_LOCAL_STORAGE_KEY) || '');
      // eslint-disable-next-line no-empty
    } catch (e) {}

    const expired = moment(localStorageUsers.expires).isBefore(moment());
    if (force || !localStorageUsers.users || expired) {
      const loadUsers = (await axios.current
        .get('/vault/users')
        .then((data) => data.data)
        .catch(() => [])) as string[];
      localStorageUsers = {
        users: loadUsers,
        expires: moment().add(15, 'minutes').toDate(),
      };
      localStorage.setItem(USERS_LOCAL_STORAGE_KEY, JSON.stringify(localStorageUsers));
    }
    return localStorageUsers.users || [];
  };

  const create = (name: string, username: string, secret: string, url: string, successMessage?: string) =>
    resolvePromise(
      axios.current.post('/vault', { name, username, secret, url }),
      successMessage || 'New secret created',
    );

  const update = (id: string, version: number, document: UpdateVault, successMessage?: string) =>
    resolvePromise(axios.current.patch(`/vault/${id}`, { version, ...document }), successMessage || 'Secret updated');

  const addAllowedUser = (id: string, version: number, name: string, successMessage?: string) =>
    resolvePromise(
      axios.current.patch(`/vault/${id}/add-allowed`, { name, version }),
      successMessage || `${name} allowed to share secret`,
    );

  const removeAllowedUser = (id: string, version: number, name: string, successMessage?: string) =>
    resolvePromise(
      axios.current.patch(`/vault/${id}/remove-allowed`, { name, version }),
      successMessage || `${name} not allowed to share secret`,
    );
  const addIssuedUser = (id: string, version: number, name: string, successMessage?: string) =>
    resolvePromise(
      axios.current.patch(`/vault/${id}/add-issued`, { name, version }),
      successMessage || `secret shared with ${name}`,
    );
  const removeIssuedUser = (id: string, version: number, name: string, successMessage?: string) =>
    resolvePromise(
      axios.current.patch(`/vault/${id}/remove-issued`, { name, version }),
      successMessage || `secret not shared with ${name}`,
    );
  const setActive = (id: string, version: number, active: boolean, successMessage?: string) =>
    resolvePromise(
      axios.current.patch(`/vault/${id}/set-active`, { version, active }),
      successMessage || `secret is ${active ? '' : 'in'}active`,
    );

  return {
    find,
    reload,
    create,
    getById,
    getUsers,
    update,
    addAllowedUser,
    removeAllowedUser,
    addIssuedUser,
    removeIssuedUser,
    setActive,
  };
};
