import { useRouteMatch } from 'react-router';
import SecretsPage from '../../Secrets/Layout/SecretsPage';
import { ModulePrivateRoute } from './private-routes.service';
export const usePrivateDashboardModules = (): ModulePrivateRoute[] => {
  const match = useRouteMatch();
  return [
    {
      title: 'Secrets',
      exact: true,
      path: `${match.url}/secrets`,
      component: SecretsPage,
      accessModule: 'vault-service',
    },
  ];
};
