import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ScrollableTabs } from '../Components/ScrollableTabs';

const SecretsPage = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography color='inherit' variant='h4'>
          Secrets
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ScrollableTabs />
      </Grid>
    </Grid>
  );
};

export default SecretsPage;
