import { AuthClientError, AuthClientEvent } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import React from 'react';
//Other
import ReactDOM from 'react-dom';
import App from './App';
//Custom
import './index.css';
import * as serviceWorker from './serviceWorker';

const keycloak = Keycloak('/keycloak.json');

const eventLogger = (eventType: AuthClientEvent, error?: AuthClientError) => {
  console.log('onKeycloakEvent', eventType, error);
  // if (eventType === 'onAuthSuccess' || eventType === 'onAuthRefreshSuccess')
  //   keycloak.loadUserProfile().catch((e) => console.log({ e }));
};

const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens);
};

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    // initOptions={{ onLoad: 'login-required' }}
    onEvent={eventLogger}
    onTokens={tokenLogger}
  >
    <App />
  </ReactKeycloakProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
