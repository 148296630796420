import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';
import React from 'react';
// import { useHistory, useRouteMatch } from 'react-router-dom';
import { TabPanel } from '../../Common/Layout/Components/TabPanel';
import { SecretsTable } from './Tables/SecretsTable';
import { secretsTableConfig } from './Tables/SecretsTableConfig';

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
export const ScrollableTabs = () => {
  const [value, setValue] = React.useState(0);
  // const history = useHistory();
  // const match = useRouteMatch();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container direction='column' spacing={5}>
      <Grid item xs={12}>
        <AppBar position='static' color='default'>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable tabs'
          >
            <Tab label='Add' {...a11yProps(0)} />
          </Tabs>
        </AppBar>
      </Grid>
      <TabPanel value={value} index={0}>
        <Grid item xs={12}>
          <SecretsTable config={secretsTableConfig} rowsPerPageOptions={[10, 25, 50]} />
        </Grid>
      </TabPanel>
    </Grid>
  );
};
