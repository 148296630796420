import React from 'react';

interface TabPanelProps {
  index: number | number[];
  value: number;
}
export const TabPanel = (props: React.PropsWithChildren<TabPanelProps>) => {
  const { children, value, index } = props;

  return <>{typeof index === 'number' ? value === index && children : index.includes(value) && children}</>;
};
