//MUI
/* Core */
import { Card, CardActions, CardContent, CardHeader, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
//Router
import { useLocation } from 'react-router-dom';
//Custom
import logo from '../Util/Assets/Laco-logo-slogan.png';

const useStyles = makeStyles({
  root: {
    height: 100 + 'vh',
    backgroundImage: 'linear-gradient(to top right, #ff8f8f, #ff2424)',
    alignItems: 'center',
  },
  card: {
    width: 342,
  },
  media: {
    width: 302 + 'px',
    height: 100 + 'px',
    marginLeft: 20,
  },
});

export const LoginPage = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation<{ from?: string }>();
  const classes = useStyles();
  const referrer = `${window.location.href.slice(0, -6)}${location?.state?.from || '/'}`;

  const logout = () => keycloak?.logout();

  const login = (options?: Keycloak.KeycloakLoginOptions) => keycloak?.login(options);

  React.useEffect(() => {
    //force logout on load
    if (keycloak.authenticated) logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container justify='center' spacing={2}>
          <Grid item>
            <Card className={classes.card} raised>
              <CardHeader title='Login' />
              <CardMedia className={classes.media} image={logo} title='Logo' />
              <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                  Application Name
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  Application description
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <button onClick={() => login({ redirectUri: referrer })}> Login</button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
