//MUI
/* Core */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { isArray } from 'lodash';
import React from 'react';
import { tableDataTypeEnum } from '../ApiTable/api-table-config.interface';
import { MinMaxValues } from '../ApiTable/ApiTable';

interface FilterDialogProps {
  open: boolean;
  filterType: tableDataTypeEnum;
  initFilterValue: MinMaxValues | string | string[];
  columnName: string;
  closeDialog: () => void;
  filterAdded: (filterValue: string | MinMaxValues | string[]) => void;
  enums?: string[];
}
export const FilterDialog = (props: FilterDialogProps) => {
  const { open, filterType, initFilterValue, filterAdded, columnName, closeDialog, enums } = props;
  const [filterValue, setFilterValue] = React.useState(initFilterValue);
  const [minDateError, setMinDateError] = React.useState(false);
  const [maxDateError, setMaxDateError] = React.useState(false);
  // const [filterDateValue, setFilterDateValue] = React.useState<Date | null>(null);
  //   const [placeTypeAddress, setPlaceTypeAddress] = React.useState<PlaceType | null>();
  //   const [addressError, setAddressError] = React.useState(false);
  // React.useEffect(() => {
  //   filterType === tableDataTypeEnum.DATE && filterValue
  //     ? setFilterDateValue(new Date(filterValue))
  //     : setFilterDateValue(null);
  // }, [filterValue]);
  // React.useEffect(() => {
  //   setFilterTextValue(filterValue);
  // }, [filterValue, open]);

  React.useEffect(() => {
    setFilterValue(initFilterValue);
  }, [initFilterValue]);

  const numberError =
    typeof filterValue !== 'string' &&
    !isArray(filterValue) &&
    filterValue.maxNumber !== undefined &&
    filterValue.minNumber !== undefined &&
    filterValue.maxNumber <= filterValue.minNumber;

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{columnName}</DialogTitle>
      <DialogContent>
        <Grid container justify='center' alignContent='center' alignItems='center'>
          {filterType === tableDataTypeEnum.STRING && (
            <TextField
              autoFocus
              fullWidth
              id='filter-field'
              label='Filter'
              value={filterValue}
              onChange={(event) => setFilterValue(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  filterAdded(filterValue);
                  closeDialog();
                }
              }}
            />
          )}
          {filterType === tableDataTypeEnum.DATE && !isArray(filterValue) && (
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  autoFocus
                  disableToolbar
                  variant='inline'
                  format='MM/dd/yyyy'
                  margin='none'
                  id='min-filter-date-picker'
                  label='Min Date'
                  maxDate={typeof filterValue !== 'string' ? filterValue.maxDate : undefined}
                  value={typeof filterValue === 'string' || !filterValue.minDate ? null : filterValue.minDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  fullWidth
                  onChange={(minDate) =>
                    typeof filterValue !== 'string'
                      ? minDate
                        ? setFilterValue({ ...filterValue, minDate: minDate.toDateString() })
                        : delete filterValue.minDate
                      : minDate && setFilterValue({ minDate: minDate.toDateString() })
                  }
                  onError={(event) =>
                    event === 'Date should not be before minimal date' || event === 'Invalid Date Format'
                      ? setMinDateError(true)
                      : setMinDateError(false)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='MM/dd/yyyy'
                  margin='none'
                  id='max-filter-date-picker'
                  label='Max Date'
                  minDate={typeof filterValue !== 'string' ? filterValue.minDate : undefined}
                  value={typeof filterValue === 'string' || !filterValue.maxDate ? null : filterValue.maxDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  fullWidth
                  onChange={(maxDate) =>
                    typeof filterValue !== 'string'
                      ? maxDate
                        ? setFilterValue({ ...filterValue, maxDate: maxDate.toDateString() })
                        : delete filterValue.maxDate
                      : maxDate && setFilterValue({ maxDate: maxDate.toDateString() })
                  }
                  onError={(event) =>
                    event === 'Date should not be before minimal date' || event === 'Invalid Date Format'
                      ? setMaxDateError(true)
                      : setMaxDateError(false)
                  }
                />
              </Grid>
            </Grid>
          )}
          {filterType === tableDataTypeEnum.ENUM &&
            enums &&
            enums.map((value, index) => (
              <Box key={index} minWidth={150} flex={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isArray(filterValue) ? filterValue.includes(value) : false}
                      color='primary'
                      onChange={(event, checked) => {
                        if (isArray(filterValue)) {
                          if (checked) {
                            setFilterValue([...filterValue, value]);
                          } else {
                            setFilterValue(filterValue.filter((v) => v !== value));
                          }
                        } else {
                          setFilterValue([value]);
                        }
                      }}
                    />
                  }
                  label={value.charAt(0).toUpperCase() + value.substring(1)}
                  labelPlacement='end'
                />
              </Box>
            ))}
          {filterType === tableDataTypeEnum.NUMBER && !isArray(filterValue) && (
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id='min-filter-field'
                  label='Min Number'
                  type='number'
                  value={typeof filterValue === 'string' || !filterValue.minNumber ? '' : filterValue.minNumber}
                  onChange={(event) =>
                    typeof filterValue !== 'string'
                      ? event.target.value
                        ? setFilterValue({ ...filterValue, minNumber: parseInt(event.target.value) })
                        : delete filterValue.minNumber
                      : event.target.value && setFilterValue({ minNumber: parseInt(event.target.value) })
                  }
                  error={numberError}
                  helperText={numberError ? 'Min must be smaller than max' : ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id='max-filter-field'
                  label='Max Number'
                  type='number'
                  value={typeof filterValue === 'string' || !filterValue.maxNumber ? '' : filterValue.maxNumber}
                  onChange={(event) =>
                    typeof filterValue !== 'string'
                      ? event.target.value
                        ? setFilterValue({ ...filterValue, maxNumber: parseInt(event.target.value) })
                        : delete filterValue.maxNumber
                      : event.target.value && setFilterValue({ maxNumber: parseInt(event.target.value) })
                  }
                  error={numberError}
                  helperText={numberError ? 'Max must be larger than min' : ''}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            filterAdded(filterValue);
            closeDialog();
          }}
          disabled={minDateError || maxDateError || numberError}
        >
          Set Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};
